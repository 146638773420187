import React from "react"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {verRequisitos, volverBTN} from "./ModalInscripciones.js"
import "../css/Niveles.css";
import "../css/Botones.css";
import "./Button";
import Lottie from 'react-lottie';
import business from '../animations/business.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  renderSettings: {
    preserveAspectRadio: 'xMidYMid slice'
  }
}

export const PopoverSoyNuevo = (
    <OverlayTrigger trigger="hover" placement="right" overlay={
      <Tooltip id="button-tooltip-2">Inscribirse para el secundario acelerado.</Tooltip>}>
        <spam variant="success" className="popover-soy-nuevo pointer-cursor">?</spam>
      </OverlayTrigger>
    );
export const PopoverSoyInst = (
      <OverlayTrigger trigger="hover" placement="right" overlay={
        <Tooltip id="button-tooltip-2">Información de contacto</Tooltip>}>
          <spam variant="success" className="popover-soy-inst pointer-cursor">?</spam>
        </OverlayTrigger>
      );

export const botonesElegir = (
  <div className="botones-secundario">
      <Lottie width='200px' options={{animationData: business, ...defaultOptions}} />
            <h3 className="modal-title text-center">Bienvenido</h3>
            <p className="modal-text text-center">Si sos aspirante seleccioná "soy nuevo". <br/>Para completar el formulario elige una opción.</p>
    <div>
      <hr/>
      <spam className="modal-button btn " data-sm-link-text="Ver requisitos" onClick={() => verRequisitos(solicitudReq)}><span>Soy nuevo</span></spam> {PopoverSoyNuevo}</div>
    <div><spam className="modal-button btn " data-sm-link-text="Ver requisitos" onClick={() => verRequisitos(puntoDeContactoReq)}><span>Institución</span></spam> {PopoverSoyInst}</div>
  </div>
);


const primeroMat = (
  <ul>
    <li>Matematica</li>
    <li>Lengua y Literatura</li>
    <li>Ingles</li>
    <li>Quimica - Fisica</li>
    <li>Biologia</li>
    <li>Geografia</li>
    <li>Historia</li>
    <li>Taller de Ciudadania y Part.</li>
    <li>Formacion para la vida y trabajo</li>
    <li>Taller de Orientacion Vocacional Ocupacional</li>
  </ul>
);
export const primero = {
  titulo: "1° Año",
  materias: primeroMat
}

const segundoMat = (
  <ul>
    <li>Matematica</li>
    <li>Lengua y Literatura</li>
    <li>Ingles</li>
    <li>Quimica - Fisica</li>
    <li>Historia</li>
    <li>Psicologia Social</li>
    <li>Problematicas Economicas Actuales</li>
    <li>Derecho del Trabajador y  Seguridad Social</li>
    <li>Sistema de Inf Contable 1</li>
  </ul>
);
export const segundo = {
  titulo: "2° Año",
  materias: segundoMat
}
const terceroMat = (
  <ul>
    <li>Matematica</li>
    <li>Lengua y Literatura</li>
    <li>Ingles</li>
    <li>Problematicas de Salud Humana y Ambiental</li>
    <li>Sociologia</li>
    <li>Espacio y Sociedad en Argentina y Latam</li>
    <li>Sistema de Inf Contable 2</li>
    <li>Economia</li>
    <li>Cooperativa y Entidades sin Fines de Lucro</li>
    <li>Espacion de Vinculacion con el Sector Orientacion</li>
  </ul>
);
export const tercero = {
  titulo: "3° Año",
  materias: terceroMat
}
  export const puntoDeContactoReq = (
    <div className="card-modal m-5">
      <spam onClick={() => volverBTN(botonesElegir)}><i class="fas fa-arrow-left"></i></spam>
      {/* <img className="img-promo noc"/> */}
      <h2 style={{fontWeight: 900}}>PUNTO DE CONTACTO</h2>
      <br></br>
      <div className="posicionamientoboton">
        <p style={{marginTop: 10, marginBottom:50}}>secretariawcmnocturno@gmail.com</p>
      </div>
      <div className="posicionamientoboton">
        <p><b>351-6317715 (WhatsApp Secretaria Acelerado)</b></p>
      </div>
    </div>
  )

  export const solicitudReq = (
    <div className="card-modal m-3">
        <spam onClick={() => volverBTN(botonesElegir)}><i class="fas fa-arrow-left"></i></spam>
        <h1 className="text-center"><b>Año 2025</b></h1>
        {/* <img className="img-promo noc"/> */}
        <h2 style={{fontWeight: 900}}>Solicitud de Banco</h2><br/>

        <h4><b>FORMALIZACIÓN DE LAS SOLICITUDES</b></h4>
        Sólo se puede presentar la solicitud de pre-inscripción completando el formulario Online.
        <div className="posicionamientoboton">
            <a 
            target="_blank"
            rel="noopener noreferrer"
            href="https://forms.gle/LDGtZfufe2RXBCBp8"
            className="modal-button btn">Solicitud de PreInscripcion
           
            </a>
        </div>
        <p >Una vez completada la solicitud de pre-inscripción, le llegara un correo electrónico indicando las instrucciones a seguir para formalizar la inscripción definitiva.</p>
        <p>Para concluir el trámite de matriculación deberá presentar en la institución la siguiente documentación al principio del año lectivo 2025:</p>
        
        <ul>
        <li>Certificado de estudios Primario Completo o Constancia de Documentos. (Solicitar en el Colegio donde cursó 1º año).</li>
        <li>D.N.I. ACTUALIZADO (original y fotocopia de ambos lados).</li>
        <li>1 Foto carnet actualizada.</li>
        <li>Certificado de Estudios en Trámites. (para saber que año ingresaría)</li>
        <li>Constancia de Cuil.</li>
      </ul>
    </div>
  )