import React , { useEffect} from "react";
import "../css/DropJQuery.css";
import $ from "jquery";
window.jQuery = $;

export default function DropdownList(props) {
 
  useEffect(() => {
    //Botón de acción del acordeón
    $('.dropdownButton').click(function() {

      //Elimina la clase on de todos los botones
      $('.dropdownButton').removeClass('on');
      $('.dropdownButton').addClass('borderButtom');
      // Elimino flecha down
      $('.dropdownButton').find('i').removeClass('fa-chevron-up');
      $('.dropdownButton').find('i').addClass('fa-chevron-down');
      //Plegamos todo el contenido que esta abierto
      $('.dropdownContent').slideUp(350);

      //Si el siguiente slide no esta abierto lo abrimos
      if($(this).next().is(':hidden') === true) {

        //Añade la clase on en el botón
        $(this).addClass('on');
        $(this).removeClass('borderButtom');
        // Añado clase down
        $(this).find('i').removeClass('fa-chevron-down');
        $(this).find('i').addClass('fa-chevron-up');
        

        //Abre el slide
        $(this).next().slideDown(350);
      }

    });

    // Cerramos todo el contenido al cargar la página
    $('.dropdownContent').hide();
  }, []);
  const lista = props.lista;
  return (
    <>
      {lista.map((x) =>
          <div className="dropdownMenu">
            <div className="title dropdownButton borderButtom">
                <p><i className="fas fa-chevron-down"/>{x.titulo}</p>
            </div>
            <div className="dropdownContent">
              {x.materias}
            </div>
          </div>
      )}
    </>
  )
}
