import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  primero,
  segundo,
  tercero,
  cuarto,
  quinto,
  sexto,
  botonesElegir,
} from "../ConstantesSecundario.js";
import { Parallax } from "react-parallax";
import ModalInscripciones from "../ModalInscripciones.js";
import TituloSubtitulo from "../TituloSubtitulo";
import ContactNiveles from "../ContactNiveles";
// import Encabezado from "../Encabezado";
import EncabezadoSimple from "../EncabezadoSimple";
// import imageFloat4 from "../../img/chica.png";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../css/ContactNiveles.css";
import "../../css/CuerpoSecundario.css";
import "../../css/Niveles.css";
import "../../css/Botones.css";
import "../../css/Secundario.css";
import "../Button";
import DropdownList from "../DropdownList";

export default function Secundario() {
  useEffect(() => {
    Aos.init({});
    Aos.refresh({});
  }, []);

  return (
    <>
      <EncabezadoSimple clases="gris" />

      <div data-aos="fade-up" data-aos-duration="1000" className="publiSec mb-1">
        <div data-aos="fade-up" data-aos-duration="2000" className="posBotNoc">
          <p className="bt-class positionModalSec  ">
            <ModalInscripciones
              classBtn={"bt-1-white"}
              titulo={"Inscripción Secundario"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={botonesElegir}
            />
          </p>
        </div>
      </div>

      {/* Titulo, y dos subtitulos */}
      <TituloSubtitulo
        subtitulo="Nivel Secundario"
        text="Nuestros/as estudiantes potencian su desarrollo intelectual, creativo, la autoestima, la expresividad y las relaciones interpersonales a través de una enseñanza integral, artística y musical que los prepara para enfrentar cualquier desafío. Cada uno/a es reconocido en su individualidad y no como parte de una masa."
      />
      {/* Separador con foto */}
      <div className="separador">
        <Parallax
          // strength={props.strength}
          bgImage={require("../../img/study.jpg")}
          className={"separador-image-3"}
        />
      </div>

      {/* /////////////////////////// */}
      <Container className=" salas-conocimiento">
        <Row>
          <Col md={6} className="salitas">
            <h3 className="salitas-subtitulo salitas-subtitulo-noc c4">
              Nivel Secundario
            </h3>
            <h1 className="salitas-titulo">Materias:</h1>
            <DropdownList
              lista={[primero, segundo, tercero, cuarto, quinto, sexto]}
            />
            <h1>Turno: Tarde</h1>
          </Col>
          <Col md={6}>
            <div className="cuadrito">
              <h2>Campos de Conocimiento:</h2>
            </div>
            <div className="cuadrito-text">
              <p>
                Durante los seis años de estudio favorecemos un enfoque
                multidisciplinar, ampliando la mirada científica, creativa y
                emocional.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <ContactNiveles
        color="c2"
        nivel="secundario"
        emailSend="recepcionsecundario@gmail.com"
      />
    </>
  );
}
