import React, {useEffect} from "react";
import { Container, Row, Col} from "react-bootstrap";
import Encabezado from "../Encabezado";
import "../../css/Institucion.css";
import Aos from 'aos';
import "aos/dist/aos.css";

export default function Institucion() {

  useEffect(() => {
    Aos.init({});
  }, [])
  return (
    <>
      <Encabezado
        // fondo={require("../../img/school.jpg")}
        nivel={"Institucion"}
        frase={"Colegio Evangelico William C. Morris"}
        strength={400}
      />
      <Container className="container">
        <Row className="row" data-aos="fade-up">
          <Col md={6} xs={12} sm={12}>
            <h2 className="featurette-heading titulo-inst  pt-5">
              Historia
            </h2>
            <p className="lead contenido-texto pt-2">
              Proximamente...
            </p>
          </Col>
          <Col md={6} xs={12} sm={12}>
            <Row className="fondo foto-william mt-5" fluid />
          </Col>
        </Row>
        <hr class="featurette-divider pt-5"></hr>
        <Row className="row" data-aos="fade-up">
          <Col md={6} xs={12} sm={12}>
            <Row className="fondo foto-chica" fluid />
          </Col>
          <Col md={6} xs={12} sm={12}>
            <h2 className="titulo-inst pt-5">Mision y vision</h2>
            <p className="lead contenido-texto pt-2">
              Proximamente...
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
