import React from "react";
import "../../App.css";
import "../../css/Inicio.css";
import Cards from "../Cards";
import SobreNosotros from "../SobreNosotros";
import TituloSubtitulo from "../TituloSubtitulo";
// import Encabezado from "../Encabezado";
// import Carousel from "../Carousel"
// import Separador from "../Separador";
import Mensaje from "../Mensaje";
import EncabezadoSimple from "../EncabezadoSimple";
import { Link } from "react-scroll";

export default function Inicio() {

  return (
    <>
      {/* <Encabezado
        fondo={require("../../img/bible.jpg")}
        nivel={"Colegio William C. Morris"}
        frase={"Mas de 60 años educando en valores cristianos"}
        strength={900}
      /> */}
      <EncabezadoSimple clases="black" />
      <div className="cont-encabezado">
        <div data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000" className="encabezadoInicio">
        </div>
        <div className="clickscroll" >
          <Link className="botonClickAqui default" to="cardNiveles" smooth={true} duration={1000}>
            Click Aqui
          </Link>
        </div>
        {/* <div className="wave">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave-dibujo">
            <path fill="#fff" fill-opacity="1" d="M0,96L48,106.7C96,117,192,139,288,154.7C384,171,480,181,576,170.7C672,160,768,128,864,101.3C960,75,1056,53,1152,69.3C1248,85,1344,139,1392,165.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
          </svg>
        </div> */}
      </div>

      {/* <Carousel /> */}
      <TituloSubtitulo
        subtitulo="BIENVENIDOS"
        text="Esta Institución Educativa surge desde la visión de la iglesia
        Evangélica Cristiana, con el propósito de dar a conocer el Reino
        de Dios, bendecir a las familias e influir con valores cristianos
        a la comunidad. Estos valores y principios de vida que se
        desprenden de la Palabra de Dios, la Biblia, son nuestro
        fundamento: la Verdad, la Justicia, el Compromiso, la Tolerancia,
        la Responsabilidad, la Honestidad, la Generosidad, el
        Compañerismo, la Solidaridad y el Respeto. Por tal motivo, todos
        los estudiantes reciben diariamente por parte de sus docentes y
        autoridades espirituales del colegio, una reflexión bíblica y
        comparten un tiempo de oración."
      />
      {/* <Separador/> */}
      <Cards />
      {/* <Mensaje/> */}
      <SobreNosotros />
    </>
  );
}