import React, {useEffect, useRef, useState} from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../css/ContactNiveles.css";
import "../css/Escribenos.css";
import Aos from 'aos';
import "aos/dist/aos.css";
import { useForm } from 'react-hook-form';
// import clienteAxios  from "../config/axios";
import axios from "axios";
import { Spinner } from "react-bootstrap";

export default function ContactNiveles(props) {
  const {register, errors, handleSubmit} = useForm();
  const [sending, SetSending]=useState(null);
  const dest = props.emailSend;
  const formRef = useRef(null);

  const onSubmit = async data=>{
    SetSending("Enviando...")
    data["destinatario"] = dest;
    await axios.post(process.env.REACT_APP_APIDEV,data)
    .then(res =>{
      alert(res.data)
    })
    .catch(error =>{
      alert(error);
    });
    SetSending(null);
    formRef.current.reset();
  }
  
  useEffect(() => {
    Aos.init({});
  }, [])
  
  return (
    <>
      <Container className="contenedor-contact-niveles"  data-aos="fade-up">
        <h2 className="envianos-un-mensaje" id={props.color}>Envianos <br></br> un mensaje</h2>
        <hr className="envianos-un-mensaje-hr mb-5"id={props.color} ></hr>
        <p id={props.color}>Para: {props.emailSend}</p>
        <Form 
          onSubmit={handleSubmit(onSubmit)}
          ref={formRef} 
        >
          <Row>
            <Col md={6} className="inputs">
              <Form.Control 
                className="mb-3" 
                placeholder="Nombre" 
                name="nombre"
                ref={
                  register({
                    required: {value: true, message: 'Este campo es obligatorio'}
                  })
                }
              />
              <span className="text-danger text-small d-block mb-2">
                  {errors?.nombre?.message}
              </span>
              <Form.Control 
                placeholder="Telefono" 
                className="mb-3" 
                name="telefono"
                ref={
                  register({
                    required: {value: true, message: 'Este campo es obligatorio'}
                  })
                }
              />
              <span className="text-danger text-small d-block mb-2">
                  {errors?.telefono?.message}
              </span>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="email"
                  placeholder="tucorreo@ejemplo.com"
                  className="mb-3"
                  name="correo"
                  ref={
                    register({
                      required: {value: true, message: 'Este campo es obligatorio'}
                    })
                  }
                />
                <span className="text-danger text-small d-block mb-2">
                    {errors?.correo?.message}
                </span>
              </Form.Group>
            </Col>
            <Col md={6} className="mensaje-form">
              <Form.Group controlId="exampleForm.ControlTextarea1" >
                <Form.Control
                    as="textarea"
                    className="area-mensaje mb-4 "
                    placeholder="Tu mensaje"
                    name="mensaje"
                    ref={
                      register({
                        required: {value: true, message: 'Este campo es obligatorio'}
                      })
                    }
                />
                <span className="text-danger text-small d-block mb-2">
                    {errors?.mensaje?.message}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <button type="submit"  className="bt-1 bt-ajust" data-aos="fade-up"> {sending ? <Spinner animation="border" variant="dark" /> : "Enviar"}</button>
        </Form>
     </Container>
    </>
  );
}
