import React, {useState} from "react";
import { Modal} from "react-bootstrap";
import ReactDOM from 'react-dom';


export function verRequisitos(e){
  ReactDOM.render(
    e, 
    document.querySelector('.modal-body')
  );

  document.getElementById('modal-inscripcion').classList.add('border-bottom-green');
  document.getElementById('modal-elegir').classList.remove('border-bottom-green');
}
export function volverBTN(e){
  ReactDOM.render(
    e, 
    document.getElementById('modal-body')
  );

  document.getElementById('modal-inscripcion').classList.remove('border-bottom-green');
  document.getElementById('modal-elegir').classList.add('border-bottom-green');
}
export function siguiente(e){
  ReactDOM.render(
    e, 
    document.getElementById('modal-body')
    );
  // const modal = document.querySelector('.modal-content');
  // modal.scrollTo({
  //   top: 0,
  //   left: 0,
  //   behavior: 'smooth'
  // });
}


export default function ModalInscripciones(props) {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

	return(
		<>
      <spam 
        className={props.classBtn}
        onClick={handleShow}>{props.titulo}
      </spam>
     
     <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="true"
        keyboard={false}
        className="modal-general"
      >
        <Modal.Header closeButton id="modal-header" className="modal-header">
          <h3 className="border-bottom-green" id="modal-elegir">{props.sub1}</h3>
          <h3 id="modal-inscripcion">{props.sub2}</h3>
        </Modal.Header>

        <Modal.Body className="modal-body" id="modal-body">
          <div className="botones-secundario">
            {props.botones}
          </div>
        </Modal.Body>

        {/* <spam className="footer-modal-cerrar" onClick={handleClose}><div className="footer-modal">Cerrar</div></spam> */}
      </Modal>
		</>
	)
}