import React from "react";
import Encabezado from "../Encabezado";
import ContactNiveles from "../ContactNiveles";
import EncabezadoSimple from "../EncabezadoSimple";


export default function Contacto() {
  return (
    <>
      {/* <Encabezado
        fondo={require("../../img/fondoMovimiento.jpg")}
        nivel={"Contacto"}
        frase={"Colegio Evangelico William C. Morris"}
        strength={150}
      /> */}
      <EncabezadoSimple clases="green"/>
      <ContactNiveles
      color="c2"
      nivel="contacto"
      emailSend="info.williamcmorris@gmail.com" 
      />
    </>
  );
}
