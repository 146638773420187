import React, { useEffect } from "react";
import { botonesElegir } from "../ConstantesInicial.js";
import TituloSubtitulo from "../TituloSubtitulo";
import CuerpoInicial from "../CuerpoInicial.js";
import ContactNiveles from "../ContactNiveles";
// import Encabezado from "../Encabezado";
import EncabezadoSimple from "../EncabezadoSimple";
import ModalInscripciones from "../ModalInscripciones.js";
// import imageFloat4 from "../../img/nene-4.png";
import "../../css/ParallaxSeparadorNiveles.css";
import "../../css/CuerpoInicial.css";
import "../../css/Niveles.css";
import "../../css/Botones.css";
import "../../css/Inicial.css";
import "../../css/Nocturno.css";
import ComponentDiv from "../ComponentDiv.js";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Inicial() {
  setTimeout(() => {
    if (document.getElementById("text-wp") !== null)
      document.getElementById("text-wp").classList.add("display-none");
  }, 7000);

  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <>
      <EncabezadoSimple clases="black" />
      {/* Usamos css de inicial */}
      <div
        data-aos="fade-down"
        data-aos-duration="1000"
        className="encabezadoInicial mb-1"
      >
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="posicionEncabezadoInicial"
        >
          <p className="bt-class positionModalIni bot-1-Noc ">
            <ModalInscripciones
              classBtn={"bt-1-white bt-1-white-correccion "}
              titulo={"Inscripción Nivel Inicial"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={botonesElegir}
            />
            <i class="fas fa-chevron-up arrowBTN"></i>
          </p>
        </div>
      </div>

      <TituloSubtitulo
        subtitulo="Nivel Inicial"
        text="Los niños/as vivencian el Nivel Inicial como una instancia de
            acompañamiento amoroso e integral del despertar de la enorme
            capacidad creativa que cada uno/a trae consigo. Proponemos de manera
            lúdica y placentera momentos para el descubrimiento y desarrollo de
            la propia individualidad, del propio potencial intelectual,
            emocional y corporal. Ensayando el maravilloso mundo del movimiento,
            las palabras, los colores y el pensamiento, los niños/as
            dan los primeros pasos en el contacto enriquecedor con el otro, en
            el vínculo con la comunidad, aprendiendo de todos y con todos.
            Aportamos así una propuesta diferente entre colegios de Nivel
            Inicial privados en Córdoba."
      />
      <div class="separador-image-1"></div>

      <CuerpoInicial />

      {/* Modal */}
      {/* <div className="cartel-ins">
        <div className="fondo-cartel">
          <div className="text-ins">
            <h1>Inscripciones <br/>abiertas 2021</h1>
          </div>
          <div
          className="posicionamientoboton bot">
            <p className="bt-class bot-1">
              <ModalInscripciones 
              classBtn= {"bt-1 bt-add"}
              titulo={"Me quiero inscribir"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={botonesElegir}
              />
            </p>
          </div>  
          <img 
          src={imageFloat4} 
          className="imageFloat4"
          />
        </div>
      </div> */}

      <ComponentDiv />

      {/* <div data-aos="fade-up" data-aos-duration="1000" className="divisor-info">
        <Row>
          <Image src={nenefoto} className="nenefoto" fluid />
          <Col xs={12} sm={12} md={5}>
            <div className="slider">
              <BackgroundSlider
                images={[image1, image2, image3, image4]}
                duration={2}
                transition={1}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} className="contenido-tarjeta">
            <h3>Kermes 2019</h3>
            <p>
              <h5>Te invitamos a pasar por nuestro instagram para ver mas fotos</h5>
              <br></br>
              <a className="iconInstagram" href="https://www.instagram.com/jardinmorris/" target="_blank">
                Click aca{"   "}
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="black" class="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
              </svg>
              </a>
              {" "}
            </p>
          </Col>
        </Row>
      </div> */}

      <a
        href="https://wa.me/543515075361?text=Hola,%20estoy%20visitando%20la%20página, necesito%20información."
        class="whatsapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p class="text-wp" id="text-wp">
          Escribinos
        </p>{" "}
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>

      <ContactNiveles
        color="c2"
        nivel="inicial"
        emailSend="w.morrisjardin@gmail.com"
      />
    </>
  );
}
