import React, {useEffect} from "react";
import { Row, Col} from "react-bootstrap";
import Aos from 'aos';
import "aos/dist/aos.css";
import "../css/SobreNosotros.css";

export default function SobreNosotros() {

  useEffect(() => {
    Aos.init({});
  }, [])
  return (
    <>
      <Row className="pancarta mt-4 mb-0" data-aos="fade-up">
        <Col
          md={6}
          sm={12}
          xs={12}
          className="image-pancarta"
        />
        <Col md={6} sm={12} xs={12}>
          <Row>
            <Col className="text-pancarta">
              <h1>Propuesta Educativa</h1>
              <p>
                Proponemos un plan de enseñanza integral que abarque los
                aspectos intelectual, físico, social y espiritual, considerando
                los lineamientos generales previstos en el Diseño Curricular de
                la Ciudad de Córdoba.
                <br /> Nuestros pilares: una enseñanza de calidad; un
                aprendizaje significativo; el diálogo como medio de resolución
                de conflictos; el respeto por la diversidad; la alegría y una
                buena predisposición para la tarea diaria; la formación de un
                pensamiento crítico; el descubrimiento y desarrollo de
                habilidades, actitudes y aptitudes para vivir e influir como
                personas de bien en la sociedad; la valoración y respeto por las
                normas de convivencia, dentro y fuera de la escuela.
                
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="bloque-negro"></div>
    </>
  );
}
