import React, {useEffect} from "react";
import { Row, Col, Container} from "react-bootstrap";
import ModalInscripciones from "./ModalInscripciones.js";
import * as inicial from "./ConstantesInicial.js";
import * as primario from "./ConstantesPrimario.js";
import * as secundario from "./ConstantesSecundario.js";
import * as nocturno from "./ConstantesNocturno.js";
import Aos from 'aos';
import "aos/dist/aos.css";
import "../css/Cards.css";

export default function () {

  useEffect(() => {
    Aos.init({});
  }, [])
  return (
    <>
    <div id="cardNiveles" className="pt-4">

      <Container fluid className="content-externo mb-5"  
        data-aos="fade-up">
        <h1 className="pt-3 text-center" style={{ fontWeight: 900 }}>
          Niveles
        </h1>
        <hr className="linea" />
        <div className="contenedorTarjeta">
          <Row>
            <Col xs={12} sm={6} md={0} lg={3} className="mr-medidas-5 mb-2">
              <div className="tarjeta">
                <div className="fotoCard fotoUno"></div>
                <div className="botonCard">
                  <a href="/inicial" className="verMasBTN">Ver mas</a> <br></br>
                </div>
              </div>
              <ModalInscripciones
              classBtn={"inscribirmeBTN n1"}
              titulo={"Inscripcion"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={inicial.botonesElegir}/>
            </Col>
            <Col xs={12} sm={6} md={0} lg={3} className="mr-medidas-5 mb-2">
              <div className="tarjeta">
                <div className="fotoCard fotoDos"></div>
                <div className="botonCard">
                  <a href="/primario" className="verMasBTN">Ver mas</a> <br></br>
                </div>
                <div className="contenidoTarjeta" style={{ top: 150 }}>
                </div>
              </div>
              <ModalInscripciones
              classBtn={"inscribirmeBTN n2"}
              titulo={"Inscripcion"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={primario.botonesElegir}/>
            </Col>
            <Col xs={12} sm={6} md={0} lg={3} className="mr-medidas-5 mb-2">
              <div className="tarjeta">
                <div className="fotoCard fotoTres"></div>
                <div className="botonCard">
                  <a href="/secundario" className="verMasBTN">Ver mas</a> <br></br>
                </div>
                <div className="contenidoTarjeta" style={{ top: 130 }}>
                </div>
              </div>
              <ModalInscripciones
              classBtn={"inscribirmeBTN n3"}
              titulo={"Inscripcion"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={secundario.botonesElegir}/>
            </Col>
            <Col xs={12} sm={6} md={0} lg={3} className="mr-medidas-5 mb-2">
              <div className="tarjeta">
                <div className="fotoCard fotoCuatro"></div>
                <div className="botonCard">
                  <a href="/nocturno" className="verMasBTN">Ver mas</a> <br></br>
                </div>
                <div className="contenidoTarjeta" style={{ top: 120 }}>
                </div>
              </div>
              <ModalInscripciones
              classBtn={"inscribirmeBTN n4"}
              titulo={"Inscripcion"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={nocturno.botonesElegir}/>
            </Col>
          </Row>
          
        </div>
      </Container>
    </div>
    </>
  );
}
