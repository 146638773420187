import React, {useEffect} from "react";
import {Row, Col,Container} from "react-bootstrap";
import { Parallax } from "react-parallax";
import {botonesElegir,primero, segundo, tercero} from "../ConstantesNocturno.js";
import ModalInscripciones from "../ModalInscripciones.js";
import ContactNiveles from "../ContactNiveles";
import EncabezadoSimple from "../EncabezadoSimple";
// import imageFloat4 from "../../img/nocturno.png";
import TituloSubtitulo from "../TituloSubtitulo";
import Aos from 'aos';
import "aos/dist/aos.css";
import "../../css/CuerpoSecundario.css";
import "../../css/Nocturno.css";
import "../../css/Niveles.css";
import "../../css/Botones.css";
import "../Button";
import  DropdownList from "../DropdownList";

export default function Nocturno() {
  
  useEffect(() => {
    Aos.init({});
    Aos.refresh({});
  }, [])

  setTimeout(() => {
    if (document.getElementById('text-wp') !== null)
        document.getElementById('text-wp').classList.add('display-none');
  }, 7000);
  
  

  return (
    <>
      {/* Encabezado */}
      <EncabezadoSimple/>
    
      <div data-aos="fade-up"
        data-aos-duration="1000" className="publiNoc mb-1">
        <div data-aos="fade-up"
            data-aos-duration="2000" className="posBotNoc">
          <p className="bt-class positionModalNoc bot-1-Noc ">
            <ModalInscripciones
            classBtn= {"bt-1-white"}
            titulo={"Inscripción Secundario Acelerado"}
            sub1={"Inscripción"}
            sub2={"Requisitos"}
            botones={botonesElegir}/>
          </p>
        </div>
      </div>

      <TituloSubtitulo
        subtitulo="Secundario Acelerado"
        text=""
      />
      
      {/* <div className="separador">    
        <Parallax
            // strength={props.strength}
            // bgImage={require("../../img/school1.jpg")}
            className={"separador-image-4"}/>
      </div> */}

      {/* /////////////////////////// */}
      <Container className=" salas-conocimiento">
        <Row>
          <Col md={6} className="salitas">
            <h3 className="salitas-subtitulo salitas-subtitulo-noc c4">Secundario Acelerado</h3>
            <h1 className="salitas-titulo">Materias en:</h1>
            <DropdownList lista={[primero, segundo, tercero]} />
          </Col>
          <Col md={6}>
            <div className="cuadrito">
              <h2 className="c1 nocnoc">Campos de Conocimiento:</h2>
            </div>
            <div className="cuadrito-text">
              <p>Te brindamos la posibilidad de completar tus estudios de la mejor manera. Te alentamos a que sigas soñando, nunca es tarde para cumplir tus anhelos y sueños.</p>
            </div>
            <h4 className="bachiller">Titulo: Bachiller  con orientación en Economía y Administración</h4>
          </Col>
        </Row>
			</Container>




      <a href="https://wa.me/543516317715?text=Hola,%20estoy%20visitando%20la%20página, necesito%20información." class="whatsapp" target="_blank" rel="noopener noreferrer"><p class="text-wp" id="text-wp">Escribinos</p> <i class="fa fa-whatsapp whatsapp-icon"></i></a>

      <ContactNiveles
      color="c2"
      nivel="secundario"
      emailSend="secretariawcmnocturno@gmail.com"
      />
    </>
  );
}

