import React from "react";
import "../css/Encabezados.css";

export default function EncabezadoSimple(props) {
  return (
    <>
        <div className= {`cabezaSimple `+props.clases}></div>
    </>
  );
}
