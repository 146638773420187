import React, {useEffect} from "react";
import Encabezado from "../Encabezado";
import TituloSubtitulo from "../TituloSubtitulo";
import { Row, Col, Container } from "react-bootstrap";
import "../../css/rrhh.css";
import Aos from 'aos';
import "aos/dist/aos.css";

export default function RRHH() {
  useEffect(() => {
    Aos.init({});
  }, [])
  return (
    <>
      <Encabezado
        fondo={require("../../img/boy-book.jpg")}
        nivel={"Recursos Humanos"}
        frase={"Mas de 60 años educando en valores cristianos"}
        strength={900}
      />
      <TituloSubtitulo
        subtitulo="¿Queres trabajar con nosotros?"
        text="Nos encantaria trabajar con vos en este ambiente perfecto para el labor en equipo, y desarrollo de nuestras capacidades al 100%. Para poder calificar tenes que cargar tu curriculum en el siguiente link, completar la solicitud de empleo y la recomendacion pastoral con la respectiva autoridad "
      />
      <Container className="content-cards">
        <Row className="justify-content-center cards-rh ">
          <Col data-aos="flip-left">
            <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfkW3_Y_QjMwuiHKdbUL_0cMw-3BLS9DZXQ_n7nFdIsX83BvA/viewform">
              <div className="card-rrhh ">
                <h2 className="c1">Carga tu curriculum</h2>
                <i className="fas fa-sticky-note icon-mid"></i>
                <h4>Click aqui</h4>
                <i className="fas fa-angle-up arrow-icon"></i>
              </div>
            </a>
          </Col>
          <Col data-aos="flip-left" data-aos-offset="300">
            <a 
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1PY1379TiZKw3XkYLfoxQ4_UeAQxH2iPx/view">
              <div className="card-rrhh">
                <h2 className="c2">Solicitud de empleo</h2>
                <i className="fas fa-pen icon-mid"></i>
                <h4>Click aqui</h4>
                <i className="fas fa-angle-up arrow-icon"></i>
              </div>
            </a>
          </Col>
          <Col data-aos="flip-left" data-aos-offset="400">
            <a 
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1igPsXCNB7p7d7qUHD7gaQOQvx1CcGx9d/view">
              <div className="card-rrhh">
                <h2 className="c3">Recomendacion pastoral</h2>
                <i className="fas fa-book-open icon-mid"></i>
                <h4>Click aqui</h4>
                <i className="fas fa-angle-up arrow-icon"></i>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
      <TituloSubtitulo
        subtitulo="Reclamos y sugerencias"
        text="Nos encantaria escuchar tu opinion y/o sugerencia para seguir creciendo juntos."
        condicional={true}
      />
      <p className="bt-class mar-no">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScS81MDE2HM8DEjlRU0Yq2UXdExrDy1Q_XNCGQfILnS4Aiz9A/viewform" target="_blank"
        rel="noopener noreferrer" className="bt-1" data-aos="fade-up">Escribir</a>
			</p>
      <TituloSubtitulo
        subtitulo="Autogestion empleados"
        text="Si trabajas en el William, podes autogestinar tus pagos aqui."
        condicional={true}
      />
      <p className="bt-class mar-no">
        <a href="https://deioweb.com.ar/SAE/" target="_blank"
        rel="noopener noreferrer" className="bt-1" data-aos="fade-up">Autogestion</a>
			</p>
    </>
  );
}
