import React from "react";
import { Row, Col, Container} from "react-bootstrap";

export default function () {

	return(
		<>
			<Container className=" salas-conocimiento">
        <Row>
          <Col md={6} className="salitas">
            <h3 className="salitas-subtitulo c1 ">Salitas del Jardin</h3>
            <h1 className="salitas-titulo">Materias por salitas en:</h1>
            <ul>
              <li>
                <h1 className="sala"> Sala de 3:</h1>{" "}
                <h1 class="sala-color">Roja</h1>{" "}
              </li>
              <li>
                <h1 className="sala"> Sala de 4:</h1>{" "}
                <h1 class="sala-color">Verde y Naranja</h1>{" "}
              </li>
              <li>
                <h1 className="sala"> Sala de 5:</h1>{" "}
                <h1 class="sala-color">Celeste y Amarilla</h1>{" "}
              </li>
            </ul>
            <h1>Turnos: Mañana y Tarde</h1>
          </Col>
          <Col md={6}>
            <div className="cuadrito">
              <h2>Campos de Conocimiento:</h2>
            </div>
            <ul>
              <li>Identidad y Convivencia</li>
              <li>Lengua y Literatura</li>
              <li>Matemática</li>
              <li>Ciencias Sociales, Ciencias Naturales y Tecnología</li>
              <li>Educación Artística: Plástica</li>
              <li>Educación Artística: Música</li>
              <li>Educación Física</li>
              <li>Educacion Cristiana</li>
              <li>Computacion</li>
              <li>Inglés</li>
            </ul>
          </Col>
        </Row>
			</Container>
		</>
	)
}