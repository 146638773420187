import React from "react";
import { Form, Container } from "react-bootstrap";
import "../css/Login.css";

export default function(){

    return(
        <>
        <Container className="pt-5 contenedorLogin">
        <h3>Login</h3>
            <div className="divContenedorLogin">
                <Form.Control
                    type="user"
                    placeholder="usuario"
                    className="mb-3 placeHolderLogin"
                    name="user"
                    
                    />
                <Form.Control
                    type="pass"
                    placeholder="password"
                    className="mb-3 placeHolderLogin"
                    name="clave"
                />
            </div>
        </Container>
        </>
    );
} 