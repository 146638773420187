import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { verRequisitos, volverBTN } from "./ModalInscripciones.js";
import "../css/Niveles.css";
import "../css/Botones.css";
import "../css/Escribenos.css";
import Lottie from 'react-lottie';
import pizzaron from '../animations/pizzaron.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  renderSettings: {
    preserveAspectRadio: 'xMidYMid'
  }
}
// import ReactDOM from 'react-dom';

export const PopoverSoyNuevo = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">Alumnos de otro colegio</Tooltip>
    }
  >
    <spam variant="success" className="popover-soy-nuevo pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);
export const PopoverSoyInst = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">
        Alumnos de la institución (Inscripción para otro grado)
      </Tooltip>
    }
  >
    <spam variant="success" className="popover-soy-inst pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);
export const PopoverPrimer = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">
        Documentación para presentar
      </Tooltip>
    }
  >
    <spam variant="success" className="popover-primero pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);

export const botonesElegir = (
  <div className="botones-secundario">
    <Lottie width='200px' options={{animationData: pizzaron, ...defaultOptions}} />
            <h3 className="modal-title text-center">Bienvenido</h3>
            <p className="modal-text text-center">Para la reserva de banco elija la <br/> opción y complete el formulario.</p>
    <div>
      <hr/>
      <spam
        className="modal-button btn "
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(nuevoReq)}
      >
        <span>Nuevos alumnos</span>
      </spam>{" "}
      {PopoverSoyNuevo}
    </div>
    <div>
      <spam
        className="modal-button btn "
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(institucionReq)}
      >
        <span>Alumnos de nuestro colegio</span>
      </spam>{" "}
      {PopoverSoyInst}
    </div>
    <div>
      <spam
        className="modal-button btn red-button"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(documentacionReq)}
      >
        <span>Documentación</span>{" "}
      </spam>{" "}
      {PopoverPrimer}
    </div>
  </div>
);
const primerMat = (
  <ul>
    <li>Lengua y Literatura: Oralidad</li>
    <li>Lengua y Literatura: Lectura</li>
    <li>Lengua y Literatura: Escritura</li>
    <li>Matemáticas</li>
    <li>Ciencias Sociales y Tecnología</li>
    <li>Ciencias Naturales y Tecnología</li>
    <li>Educación Física</li>
    <li>Educación Artística: Artes Visuales</li>
    <li>Educación Artística: Música</li>
    <li>Computación</li>
    <li>Inglés</li>
    <li>Identidad y Convivencia</li>
    <li>Educación Cristiana</li>
  </ul>
);

export const primero = {
  titulo: "1°, 2°, 3° Grado",
  materias: primerMat,
};
const cuartoMat = (
  <ul>
    <li>Lengua y Literatura: Oralidad</li>
    <li>Lengua y Literatura: Lectura</li>
    <li>Lengua y Literatura: Escritura</li>
    <li>Matemáticas</li>
    <li>Ciencias Sociales</li>
    <li>Ciencias Naturales</li>
    <li>Educación Tecnológica</li>
    <li>Educación Física</li>
    <li>Educación Artística: Artes Visuales</li>
    <li>Educación Artística: Música</li>
    <li>Computación</li>
    <li>Inglés</li>
    <li>Ciudadanía y Participación</li>
    <li>Educación Cristiana</li>
  </ul>
);
export const cuarto = {
  titulo: "4°, 5°, 6° Grado",
  materias: cuartoMat,
};

export const segundo = (
  <div
    data-aos="fade-right"
    data-aos-offset="300"
    data-aos-easing="ease-in-sine"
    className="materias"
  >
    {/* <h2>Materias segundo grado</h2> */}
    <ul>
      <li>Lengua y Literatura: Oralidad</li>
      <li>Lengua y Literatura: Lectura</li>
      <li>Lengua y Literatura: Escritura</li>
      <li>Matemáticas</li>
      <li>Ciencias Sociales y Tecnología</li>
      <li>Ciencias Naturales y Tecnología</li>
      <li>Educación Física</li>
      <li>Educación Artística: Artes Visuales</li>
      <li>Educación Artística: Música</li>
      <li>Computación</li>
      <li>Inglés</li>
      <li>Identidad y Convivencia</li>
      <li>Educación Cristiana</li>
      <li></li>
      <br></br>
    </ul>
  </div>
);
export const tercero = (
  <div
    data-aos="fade-right"
    data-aos-offset="300"
    data-aos-easing="ease-in-sine"
    className="materias"
  >
    {/* <h2>Materias tercero grado</h2> */}
    <ul>
      <li>Lengua y Literatura: Oralidad</li>
      <li>Lengua y Literatura: Lectura</li>
      <li>Lengua y Literatura: Escritura</li>
      <li>Matemáticas</li>
      <li>Ciencias Sociales y Tecnología</li>
      <li>Ciencias Naturales y Tecnología</li>
      <li>Educación Física</li>
      <li>Educación Artística: Artes Visuales</li>
      <li>Educación Artística: Música</li>
      <li>Computación</li>
      <li>Inglés</li>
      <li>Identidad y Convivencia</li>
      <li>Educación Cristiana</li>
      <li></li>
      <br></br>
    </ul>
  </div>
);
export const quinto = (
  <div
    data-aos="fade-right"
    data-aos-offset="300"
    data-aos-easing="ease-in-sine"
    className="materias"
  >
    {/* <h2>Materias quinto grado</h2> */}
    <ul>
      <li>Lengua y Literatura: Oralidad</li>
      <li>Lengua y Literatura: Lectura</li>
      <li>Lengua y Literatura: Escritura</li>
      <li>Matemáticas</li>
      <li>Ciencias Sociales</li>
      <li>Ciencias Naturales</li>
      <li>Educación Tecnológica</li>
      <li>Educación Física</li>
      <li>Educación Artística: Artes Visuales</li>
      <li>Educación Artística: Música</li>
      <li>Computación</li>
      <li>Inglés</li>
      <li>Ciudadanía y Participación</li>
      <li>Educación Cristiana</li>
    </ul>
  </div>
);
export const sexto = (
  <div
    data-aos="fade-right"
    data-aos-offset="300"
    data-aos-easing="ease-in-sine"
    className="materias"
  >
    {/* <h2>Materias sexto grado</h2> */}
    <ul>
      <li>Lengua y Literatura: Oralidad</li>
      <li>Lengua y Literatura: Lectura</li>
      <li>Lengua y Literatura: Escritura</li>
      <li>Matemáticas</li>
      <li>Ciencias Sociales</li>
      <li>Ciencias Naturales</li>
      <li>Educación Tecnológica</li>
      <li>Educación Física</li>
      <li>Educación Artística: Artes Visuales</li>
      <li>Educación Artística: Música</li>
      <li>Computación</li>
      <li>Inglés</li>
      <li>Ciudadanía y Participación</li>
      <li>Educación Cristiana</li>
    </ul>
  </div>
);

export const documentacionReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    <h2 style={{ fontWeight: 900 }}>DOCUMENTACIÓN</h2>
    <br></br>
    <ul>
      <h4>
        <b>
          Información acreditativa necesaria para completar el formulario Online
        </b>
      </h4>
      <li>Completar el formulario Online de pre-inscripción.</li>
      <li>Fotocopia del DNI del niño, de ambos lados.</li>
      <li>Fotocopia del DNI de los padres o tutor, de ambos lados.</li>
      <li>
        Informe de Progreso Escolar año 2024, de ambos lados e Informe del
        colegio año 2022 y 2023
      </li>
      <li>Fotocopia de la libreta de familia o partida de nacimiento.</li>
    </ul>
    <div className="posicionamientoboton">
      <p style={{ marginTop: 50, marginBottom: 50 }}>
        Todos las fotografías e imágenes de la documentación deben ser claras y
        nítidas.
      </p>
    </div>
    <div className="posicionamientoboton">
      <h3>Importante</h3>
    </div>
    <p>
      Los datos consignados del niño/a deberán ser veraces y no omitir
      información que se toma en carácter de Declaración Jurada.
    </p>
  </div>
);

export const institucionReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    {/* <img className="img-promo"/> */}
    <h2 style={{ fontWeight: 900 }}>PERTENECIENTE A LA INSTITUCION</h2>
    <br></br>
    <h4>
      <b>OFERTA DEL CENTRO EDUCATIVO</b>
    </h4>
    Este Centro de enseñanza realiza una oferta educativa Cristiana Evangélica
    en conformidad con los principios, objetivos y proyectos señalados en su
    Ideario Institucional.
    <br />
    <br />
    <h4>
      <b>FORMALIZACIÓN DE LAS SOLICITUDES</b>
    </h4>
    Sólo se puede presentar la solicitud de pre-inscripción completando el
    formulario Online, que será cotejada exclusivamente por el Nivel Primario.
    El solo hecho de completar este formulario no significa la matriculación
    definitiva del niño/a.
    <br />
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/hq41Q18HWDQjtJwZ6"
        className="modal-button btn"
      >
        Solicitud de PreInscripcion
      </a>
    </div>
    {/* <p>
      A partir del 16 de septiembre se podrá comenzar a abonar la pre-matrícula
      2023 para los estudiantes de nuestra Institución, tres pagos consecutivos
      (Octubre-Noviembre- Diciembre). Para acceder a este beneficio (si Ud. opta
      por esta modalidad) deberá tener las cuotas al día y abonar en forma
      conjunta pagos parciales de pre matrícula y cuotas 2022, correspondientes
      a los meses siguientes.
    </p>
    <p>
      {" "}
      El plazo para la presentación de las solicitudes de Pre-inscripción para
      el año 2023 es el comprendido entre el 16 de septiembre hasta el 16 de
      octubre de 2022, ambos días inclusive.{" "}
    </p>
    <p>
      <b>
        Una vez completada la solicitud de pre-inscripción le llegara un correo
        electrónico indicando las formas de pago para afrontar el costo de la
        matrícula.
      </b>
    </p>
    <p>
      <b>
        Plazo de abono de Matricula 2023
        comprendido del 1 de Octubre al 15 de Diciembre 2022.
      </b>
    </p> */}
    {/* <h4>
      <b>MATRÍCULA DEFINITIVA DE 2022</b>
    </h4>
    <p style={{ marginBottom: 0 }}>
      Luego de cotejarse el pago de la tercer cuota de la pre-inscripción, le
      llegara un correo electrónico y un mensaje de WhatsApp indicando pasos a
      seguir y hasta que fecha dispone para completar el formulario de matricula
      definitiva.{" "}
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.google.com/forms/d/e/1FAIpQLSeRgvsxtraVXQb2EEVBb8_H-UQxr5Hr4A-xZNi4dO3vyjxmgQ/closedform"
        className="modal-button btn"
      >
        Formulario Matricula<i class="fas fa-chevron-up arrowBoton"></i>
      </a>
    </div>
    <p>
      <b>
        *En el formulario matricula tendra que cargar la ultima hoja del
        Contrato Educativo y Conformidad, completado con la informacion
        requerida.
      </b>
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1RhQx54MQid4bTIbnGQ1Fr1gzJzU0S6rE/view?usp=sharing"
        className="modal-button btn"
        style={{ marginTop: 0 }}
      >
        Descargar Contrato<i class="fas fa-chevron-up arrowBoton"></i>
      </a>
    </div> */}
    <p style={{ marginBottom: 0 }}>
      CUS: deberá ser presentado en Febrero/Marzo 2025.
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="modal-button btn"
      >
        Descargar CUS
      </a>
    </div>
    <br />
    <b>
      Para matricular es necesario tener al día todas las cuotas del año 2024
      (es decir, las diez cuotas de aranceles mensuales de marzo hasta
      diciembre inclusive).
    </b>
    <br />
    <br />
    <div className="posicionamientoboton">
      <p>
        <b>CONTACTO: primariomorris@gmail.com</b>
      </p>
    </div>
  </div>
);

export const nuevoReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    <h2 style={{ fontWeight: 900 }}>SOY NUEVO</h2>
    <br></br>
    <h4>
      <b>OFERTA DEL CENTRO EDUCATIVO</b>
    </h4>
    Este Centro de enseñanza realiza una oferta educativa Cristiana Evangélica
    en conformidad con los principios, objetivos y proyectos señalados en su
    Ideario Institucional.
    <br />
    <br />
    <h4>
      <b>FORMALIZACIÓN DE LAS SOLICITUDES</b>
    </h4>
    Sólo se puede presentar la solicitud de pre-inscripción completando el
    formulario Online, que será cotejado exclusivamente por el Nivel Primario.{" "}
    <b>
      El hecho de completar este formulario no significa la admisión del niño/a,
      ya que depende de las posibilidades de la institución.
    </b>
    <br />
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/PQgf9s3kMdTwHfU2A"
        className="modal-button btn"
      >
        Solicitud de banco
      </a>
    </div>
    {/* <p>
      {" "}
      El plazo para la presentación de las solicitudes de banco para el año 2023
      es a partir del 16 de septiembre.{" "}
    </p>
    <p>
      Una vez completado el formulario con toda la información requerida, se
      registrará al alumno/a a una lista de espera.
    </p>
    <p>
      En caso de confirmarse la vacante, la Secretaría del Nivel Primario se
      comunicará con la familia para informar su admisión y los pasos a seguir.
    </p>
    <p>
      <b>
        Una vez completada la solicitud de pre-inscripción le llegara un correo
        electrónico indicando las formas de pago para afrontar el costo de la
        matrícula.
      </b>
    </p>
    <p>
      <b>
        Plazo de abono de Matricula 2023
        comprendido del 1 de Octubre al 15 de Diciembre 2022.
      </b>
    </p> */}
    {/* <h4>
      <b>MATRÍCULA DEFINITIVA DE 2022</b>
    </h4>
    <p style={{ marginBottom: 0 }}>
      Luego de confirmarse la admisión, le llegara un correo electrónico y un
      mensaje de WhatsApp indicando pasos a seguir y hasta que fecha dispone
      para completar el formulario de matricula definitiva.{" "}
    </p>
    <div className="posicionamientoboton">
      <a
        target="blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSeRgvsxtraVXQb2EEVBb8_H-UQxr5Hr4A-xZNi4dO3vyjxmgQ/closedform"
        className="modal-button btn"
      >
        Formulario Matricula<i class="fas fa-chevron-up arrowBoton"></i>
      </a>
    </div>
    <p>
      <b>
        *En el formulario matricula tendra que cargar la ultima hoja del
        Contrato Educativo y Conformidad, completado con la informacion
        requerida.
      </b>
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1RhQx54MQid4bTIbnGQ1Fr1gzJzU0S6rE/view?usp=sharing"
        className="modal-button btn"
        style={{ marginTop: 0 }}
      >
        Descargar Contrato<i class="fas fa-chevron-up arrowBoton"></i>
      </a>
    </div> */}
    <p style={{ marginBottom: 0 }}>
      CUS: deberá ser presentado en Febrero/Marzo 2025.
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="modal-button btn"
      >
        Descargar CUS
      </a>
    </div>
    <br />
    <div className="posicionamientoboton">
      <p>
        <b>CONTACTO: primariomorris@gmail.com</b>
      </p>
    </div>
  </div>
);
