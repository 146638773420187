import React from "react";
// import fondo from "../img/Requisitos.jpg";

export default function ComponentDiv() {

	return(
		<>
			{/* <div className="rect-fondo">
        <Image src={fondo} className="fondo-rec"/>
				<h1>PREINSCRIPCION</h1>
			</div> */}
		</>
	)
}