import React, { useState } from "react";
import "../css/Navbar.css";
import LOGO from "../img/LOGO.png";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  
  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  function reportWindowSize() {
    if (window.innerWidth > 957 && document.querySelectorAll(".AAA") != null ){
      document.querySelectorAll(".AAA").forEach(x=>x.classList.add("nav-none"))
      document.querySelector(".NIV").classList.remove("nav-none")
    }
    if (window.innerWidth < 957 && document.querySelector(".NIV") != null){
      document.querySelector(".NIV").classList.add("nav-none");
      document.querySelectorAll(".AAA").forEach(x=>x.classList.remove("nav-none"))
    }
  }
  window.addEventListener('resize', reportWindowSize);
  
  if (window.innerWidth < 957 && document.querySelectorAll(".nav-none") != null){
    document.querySelectorAll(".nav-none").forEach(x=>x.classList.remove("nav-none"));
  }
  if(window.innerWidth < 957 && document.querySelector(".NIV")!=null){
    document.querySelector(".NIV").classList.add("nav-none");
  }
  return (
    <>
      <nav className="NavbarItems">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img
            src={LOGO}
            className="logo-william"
            alt="Logo william c morris"
          />
          <spam className="text-logo">William C. Morris</spam>
        </Link>
        {/* Botton-mobile */}
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        {/* Items */}
        <div className="content-items">
          <ul className={click ? "nav-menu active topnav-left" : "nav-menu"}>
            <li>
              <NavLink to="/home" className="{isActive => 'active' + (!isActive ?  nav-links default)}" onClick={closeMobileMenu}>
                Inicio
              </NavLink>
            </li>
            {/* <li>
              <Link
                to="/institucion"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Institucion
              </Link>
            </li> */}
            {/* Dropdownnnnn//////////////// */}
            <li
              className="drop NIV"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <NavLink
                to="/niveles"
                className="{isActive => 'active' + (!isActive ?  nav-links niveles-drop default)}"
                onClick={closeMobileMenu}
              >
                Niveles <i className="fas fa-caret-down" />
              </NavLink>
              {dropdown && <Dropdown />}
              
            </li>
            {/* ///////////////////////////// */}
            <li className="nav-none AAA" >
              <Link to="inicial" className="nav-links " onClick={closeMobileMenu}>
                Inicial
              </Link>
            </li>
            <li className="nav-none AAA" >
              <Link to="primario" className="nav-links " onClick={closeMobileMenu}>
                Primario
              </Link>
            </li><li className="nav-none AAA" >
              <Link to="secundario" className="nav-links " onClick={closeMobileMenu}>
                Secundario
              </Link>
            </li><li className="nav-none AAA" >
              <Link to="nocturno" className="nav-links " onClick={closeMobileMenu}>
                Secundario Acelerado
              </Link>
            </li>
            {/* ///////////////////////////// */}
            <li>
              <NavLink to="/RRHH" className="{isActive => 'active' + (!isActive ?  nav-links default)}"  onClick={closeMobileMenu}>
                RRHH
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contacto"
                className="{isActive => 'active' + (!isActive ?  nav-links default)}"
                onClick={closeMobileMenu}
              >
                Contacto
              </NavLink>
            </li>
            <li>
              <a
                href="https://deioweb.com.ar/SAG/"
                className="nav-links corriente"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                Autogestion
              </a>
              <div className="logoauto"/>
            </li>
          </ul>
        </div>
        {/* Boton de iniciar sesion */}
        {/* <Button className="iniciar-sesion">Inciar Sesion</Button> */}
      </nav>
    </>
  );
}

export default Navbar;
