import React, { useState } from "react";
import "../css/Dropdown.css";
import { Link } from "react-router-dom";

function Dropdown() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);


  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        <li className="dropdown-inicial">
          <Link
            className="dropdown-link "
            to="inicial"
            onClick={() => setClick(false)}
          >
            Inicial
          </Link>
        </li>
        <li>
          <Link
            className="dropdown-link"
            to="primario"
            onClick={() => setClick(false)}
          >
            Primario
          </Link>
        </li>
        <li>
          <Link
            className="dropdown-link"
            to="secundario"
            onClick={() => setClick(false)}
          >
            Secundario
          </Link>
        </li>
        <li>
          <Link
            className="dropdown-link"
            to="nocturno"
            onClick={() => setClick(false)}
          >
            Secundario Acelerado
          </Link>
        </li>
      </ul>
    </>
  );
}

export default Dropdown;
