import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { verRequisitos, volverBTN, siguiente } from "./ModalInscripciones.js";
import "../css/Niveles.css";
import "../css/Botones.css";
import Lottie from 'react-lottie';
import dog from '../animations/dog.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  renderSettings: {
    preserveAspectRadio: 'xMidYMid'
  }
}

export const PopoverSoyNuevo = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">Alumnos de otro colegio</Tooltip>
    }
  >
    <spam variant="success" className="popover-soy-nuevo pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);
export const PopoverSoyInst = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">Alumnos de la institución</Tooltip>
    }
  >
    <spam variant="success" className="popover-soy-inst pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);
export const PopoverPrimer = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">
        Documentación para presentar
      </Tooltip>
    }
  >
    <spam variant="success" className="popover-primero pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);

export const documentacionReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    {/* <h2 style={{fontWeight: 900}}>DOCUMENTACIÓN</h2> */}
    <br></br>
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Documentación que se solicita anexar al completar el formulario Online.
          </p>
        </div>
      </div>
    </div>
    <ul>
      <li>Completar el formulario online de Solicitud de Banco.</li>
      <li>
        Fotocopia del DNI del niño, de ambos lados. (Tener 3, 4 o 5 años ‘’según
        corresponda’’ cumplidos al 30 de Junio de 2024.)
      </li>
      <li>Fotocopia del DNI de los padres o tutor, de ambos lados.</li>
      <li>
        Fotocopia del informe completo de Guardería o Jardín (si asistió a sala
        de 3 o 4 años).
      </li>
      <li>Fotocopia de la libreta de familia o partida de nacimiento.</li>
      <li>Fotocopia del carnet de vacunas.</li>
    </ul>
    <div className="posicionamientoboton">
      <h6>
        <strong>
          Contrato de servicios. (Descargar en el siguiente botón)
        </strong>
      </h6>
      <a
        rel="noopener noreferrer"
        href={require('../img/contratoserviciosjardin2024.pdf')} download="CONTRATO SERVICIOS 2025"
        className="modal-button btn custom"
      >
        CONTRATO SERVICIOS 2025
      </a>
    </div>
    <div className="posicionamientoboton">
      <strong>
        {" "}
        <h3 style={{ marginTop: 50, marginBottom: 50 }}>
          Todos las fotografías e imágenes de la documentación deben ser claras
          y nítidas.
        </h3>
      </strong>
    </div>
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <div className="posicionamientoboton">
            <h3>¡Importante!</h3>
          </div>
          <p>
            <b>
              Los datos consignados del niño/a deberán ser veraces y no omitir
              información que se toma en carácter de Declaración Jurada.
            </b>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export const institucionReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    {/* <h2 style={{fontWeight: 900}}>PERTENECIENTE A LA INSTITUCION</h2> */}
    <br></br>
    {/* <img className="img-promo"/> */}
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Este Centro de enseñanza realiza una oferta educativa Cristiana
            Evangélica de conformidad con los principios, objetivos y proyectos
            señalados en su Carácter Propio.
          </p>
        </div>
      </div>
    </div>
    <h3></h3>
    <div className="text-center">
      <h4>
        <b>NÚMERO POSIBLE DE VACANTES</b>
      </h4>
      <hr className="hr50"></hr>
      <p>
        Las vacantes en las distintas salas de 4 y 5 años se tendrán en cuenta
        que la prioridad se le otorga al alumno que pasa de una sección a otra.
        Se cubren los lugares disponibles hasta completar el número de alumnos.
        La prioridad es para los hermanos de alumnos de la Institución e hijos
        del personal docente y administrativo. En un tercer lugar los que
        acuerdan con el proyecto educativo.
      </p>
    </div>
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Sólo se puede presentar una solicitud de pre-inscripción completando
            el formulario Online, la cual será cotejada exclusivamente por el
            Nivel Inicial en primera instancia. El solo hecho de completar este
            formulario no significa la Matriculación del niño/a.
          </p>
        </div>
      </div>
    </div>
    <div className="posicionamientoboton">
      <h6>
        <strong>
          Llenar el siguiente formulario. (Click en el botón de abajo)
        </strong>
      </h6>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/n81qYG14Ut9JmJQz9"
        className="modal-button btn"
      >
        Solicitud de PreInscripcion
      </a>
    </div>
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Una vez completado el formulario de arriba hacé click en "siguiente"
            para continuar la inscripción
          </p>
        </div>
      </div>
    </div>
    <div className="posicionamientoboton2">
      <button
        className="text-center modal-button red-button btn"
        onClick={() => siguiente(siguiente2)}
      >
        Siguiente
      </button>
    </div>
  </div>
);

export const siguiente2 = (
  <div className="card-modal m-3">
    <spam onClick={() => siguiente(institucionReq)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    {/* <p>El plazo para la presentación de las solicitudes de Pre-inscripción para el año 2021 es el comprendido entre el 1 de septiembre hasta el 30 de septiembre de 2020, ambos días inclusive.</p>
      <p><b>Una vez completada la solicitud de pre-inscripción le llegara un correo electrónico indicando las formas de pago para afrontar el costo de la matrícula.</b></p>
      <p><b>Plazo de abono de Matricula Definitiva 2021 (abonando la última cuota) comprendido del 1 de diciembre al 15 de diciembre 2020.</b></p>
      <br/>
      <p style={{marginBottom: 0}}>CUS: deberá ser presentado en Febrero/Marzo 2021. (En caso de no volver a la presencialidad, enviarlo al mail ( w.morrisjardin@gmail.com ) y se indicará en qué momento presentarlo en formato papel)</p>
      <div className="posicionamientoboton">
        <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="bt-1-xs"
        >Descargar CUS<i class="fas fa-chevron-up arrowBoton"></i></a>
      </div><br/>
      <b>Para matricular es necesario tener al día todas las cuotas del año 2020 (es decir, las nueve cuotas de aranceles mensuales de marzo hasta noviembre inclusive).</b>
      <br/><br/> */}
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          En segunda instancia la Secretaría del Nivel Inicial se comunicará con
          la familia para informar su admisión, teniendo en cuenta que se han
          presentado toda la documentación solicitada.
        </div>
      </div>
    </div>
    <p>
      El plazo para la presentación de las solicitudes de Preinscripción para el
      año 2025 es el comprendido entre el 3 de Septiembre al 3 de Octubre.
    </p>
    <p>
    <b>Matrícula:</b> del 1 de Noviembre al 29 de Noviembre de 2024
    </p>
    {/* <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p className="text-center">
            Plazo de presentación de instancias para el proceso de
            preinscripción: Del 15 de Agosto hasta 15 de Diciembre!
          </p>
        </div>
      </div>
    </div>
    <p className="text-center">
      {" "}
      Comunicación a las familias de la admisión del niño/a del 1 de octubre al
      15 de diciembre de 2023. Si la dirección del Nivel Inicial considera
      necesario se harán entrevistas personales o por llamadas a la
      familia.
    </p> */}

    <p className="text-center">
      CUS: deberá ser presentado en Febrero/Marzo 2025.
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="modal-button btn"
        style={{ marginTop: 0 }}
      >
        Descargar CUS
      </a>
    </div>
    <br />
    <p>
      <b></b>
    </p>
    <p className="text-white text-center">CONTACTO: w.morrisjardin@gmail.com</p>
  </div>
);

export const nuevoReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    {/* <h2 style={{fontWeight: 900}}>SOY NUEVO</h2>
      <br></br> */}
    {/* <h4><b>OFERTA DEL CENTRO EDUCATIVO</b></h4> */}
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Este Centro de enseñanza realiza una oferta educativa Cristiana
            Evangélica de conformidad con los principios, objetivos y proyectos
            señalados en su Carácter Propio.
          </p>
        </div>
      </div>
    </div>
    {/* <br/><br/> */}
    <div className="text-center">
      <h4>
        <b>NÚMERO POSIBLE DE VACANTES</b>
      </h4>
      <hr className="hr50"></hr>
      El número posible de vacantes en las distintas salas de 3 (capacidad: 20
      niños), 4 y 5 años; depende de los lugares a cubrir. Tomando en cuenta que
      la prioridad se le otorga al alumno que pasa de una sección a otra. Se
      cubren los lugares disponibles hasta completar el número de alumnos. La
      prioridad es para los hermanos de alumnos de la Institución e hijos del
      personal docente y administrativo. En un tercer lugar los que acuerdan con
      el proyecto educativo.
    </div>
    <br />
    <br />

    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Sólo se puede presentar una solicitud de banco completando el
            formulario online la cual será cotejada exclusivamente por el Nivel
            Inicial en primera instancia. El solo hecho de completar este
            formulario no significa la admisión del niño/a.
          </p>
        </div>
      </div>
    </div>
    <div className="posicionamientoboton">
      <h6>
        <strong>
          Llenar el siguiente formulario. (Click en el botón de abajo)
        </strong>
      </h6>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/3p5jJXGFQJWAd7A9A"
        className="modal-button btn"
      >
        Solicitud de banco
      </a>
    </div>
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Una vez completado el formulario de arriba hacé click en "siguiente"
            para continuar la inscripción
          </p>
        </div>
      </div>
    </div>
    <div className="posicionamientoboton2">
      <button
        className="modal-button red-button btn"
        onClick={() => siguiente(siguiente1)}
      >
        Siguiente
      </button>
    </div>
    {/* <p>El plazo para la presentación de las solicitudes de banco para el año 2021 es el comprendido entre el 1 de septiembre hasta el 30 de septiembre de 2020, ambos días inclusive.</p>
      <p>En segunda instancia la Secretaría del Nivel Inicial se comunicará con la familia para informar su admisión, teniendo en cuenta que se han presentado toda la documentación solicitada
      </p>
      <p> La comunicación a las familias de la admisión del niño/a sera del 16 de octubre al 31 de octubre de 2021. Si la dirección del Nivel Inicial considera necesario se harán entrevistas personales o por videollamada a la familia. </p>
      <p><b>Una vez completada la solicitud de pre-inscripción le llegara un correo electrónico indicando las formas de pago para afrontar el costo de la matrícula.</b></p>
      <p><b>Plazo de abono de Matricula Definitiva 2021 (abonando la última cuota) comprendido del 1 de diciembre al 15 de diciembre 2020.</b></p> */}

    {/* <h4><b>MATRÍCULA DEFINITIVA DE 2021</b></h4>
      <p style={{marginBottom: 0}}>Luego de confirmarse la admisión, le llegara un correo electrónico y un mensaje de Whatsapp indicando pasos a seguir y  hasta que fecha dispone para completar el formulario de matricula definitiva.</p>
      <div className="posicionamientoboton">
        <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdVeoWsBRRs0iZem6beUdUOKf1FFP5f0D3jvHESteGpynrkdA/closedform" 
        className="bt-1-xs"
        >Formulario Matricula<i class="fas fa-chevron-up arrowBoton"></i></a>
      </div>
      <p style={{marginBottom: 0}}><b>*En el formulario matricula tendra que <b>cargar</b> el Contrato Educativo y Conformidad, completado con la informacion requerida.</b></p>
      <div className="posicionamientoboton">
        <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/15ay5iVSVjQ7A2mjC_I85QYTxm01BFQf7/view?usp=sharing"
        className="bt-1-xs"
        >Descargar Contrato<i class="fas fa-chevron-up arrowBoton"></i></a>
      </div> */}
    {/* <p >CUS: deberá ser presentado en Febrero/Marzo 2021. (En caso de no volver a la presencialidad, enviarlo al mail ( w.morrisjardin@gmail.com ) y se indicará en qué momento presentarlo en formato papel)</p>
      <div className="posicionamientoboton">
        <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="bt-1-xs"
        style={{marginTop: 0}}>Descargar CUS<i class="fas fa-chevron-up arrowBoton"></i></a>
      </div><br/>
      <p><b>Plazo de abono de Matricula 2022 comprendido entre 16 de octubre (abonando la primera cuota ‘’Plan de 3 cuotas’’ o completo) al 15 de diciembre 2021.</b></p>
      

      {/* <b>(Nota: Los alumnos que no formalicen la matrícula en los plazos establecidos perderán su plaza)</b> */}
    {/* <div className="posicionamientoboton">
          <p><b>CONTACTO:  w.morrisjardin@gmail.com</b></p>
      </div> */}
  </div>
);

export const siguiente1 = (
  <div className="card-modal m-3">
    <spam onClick={() => siguiente(nuevoReq)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          En segunda instancia la Secretaría del Nivel Inicial se comunicará con
          la familia para informar su admisión, teniendo en cuenta que se han
          presentado toda la documentación solicitada.
        </div>
      </div>
    </div>
    <p>
      El plazo para la presentación de las solicitudes de Preinscripción para el
      año 2025 es el comprendido entre el 3 de Septiembre al 3 de Octubre.
    </p>
    <p>
    <b>Matrícula:</b> del 1 de Noviembre al 29 de Noviembre de 2024
    </p>
    {/* <div className="row contenedorBocadillo">
      <div className="col col-md-1 col-lg-1 col-1"></div>
      <div className="col col-md-1 col-lg-1 col-1">
        <img
          className="imagen-bocadillo"
          src="https://img.icons8.com/flat-round/64/000000/lion.png"
        />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p className="text-center">
            Plazo de presentación de instancias para el proceso de
            preinscripción: Del 15 de Agosto hasta 15 de Diciembre!
          </p>
        </div>
      </div>
    </div> */}
    {/* <p className="text-center">
      Comunicación a las familias de la admisión del niño/a del 1 de octubre al
      15 de Diciembre de 2023. Si la dirección del Nivel Inicial considera
      necesario se harán entrevistas personales o por llamadas a la
      familia.
    </p> */}

    <p className="text-center">
      CUS: deberá ser presentado en Febrero/Marzo 2025.
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="modal-button btn"
        style={{ marginTop: 0 }}
      >
        Descargar CUS
      </a>
    </div>
    <br />
    <p>
      <b></b>
    </p>
    {/* <div className="row contenedorBocadillo">
          <div className="col col-md-1 col-lg-1 col-1">
          </div>
          <div className="col col-md-1 col-lg-1 col-1">
            <img className="imagen-bocadillo" src="https://img.icons8.com/flat-round/64/000000/lion.png"/>
          </div>
          <div className="col col-md-10 col-lg-10 col-10">
            <div id="bocadillo">
              <p>Plazo de abono de Matricula 2021 comprendido entre 12 de octubre (abonando la primera cuota ‘’Plan de 3 cuotas’’ o completo) al 15 de diciembre 2020.</p>
            </div>
          </div>
        </div> */}
    <p className="text-white text-center">CONTACTO: w.morrisjardin@gmail.com</p>
  </div>
);

export const botonesElegir = (
  <div className="botones-secundario">
    <div className="row contenedorBocadillo">
      {/* <div className="col col-md-1 col-lg-1 col-1"></div> */}
      <div className="col col-md-2 col-lg-2 col-2">
      <Lottie className="lottie-custom" width='160px' options={{animationData: dog, ...defaultOptions}} />
      </div>
      <div className="col col-md-10 col-lg-10 col-10">
        <div id="bocadillo">
          <p>
            Hola, bienvenido/a. Gracias por elegirnos. Por favor revisar el
            apartado de "Documentación".
          </p>
        </div>
      </div>
    </div>
    <div>
      <spam
        className="modal-button btn"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(nuevoReq)}
      >
        <span>Nuevos alumnos</span>
      </spam>{" "}
      {PopoverSoyNuevo}{" "}
    </div>
    <div>
      <spam
        className="modal-button btn"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(institucionReq)}
      >
        <span>Alumnos de nuestro colegio</span>
      </spam>{" "}
      {PopoverSoyInst}
    </div>
    <div>
      <spam
        className="modal-button btn red-button"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(documentacionReq)}
      >
        <span>Documentación</span>{" "}
      </spam>{" "}
      {PopoverPrimer}{" "}
    </div>
  </div>
);
