import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-parallax";
import Navbar from "./componentes/Navbar";
import Inicio from "./componentes/pages/Inicio";
import Institucion from "./componentes/pages/Institucion";
import Niveles from "./componentes/pages/Niveles";
import RRHH from "./componentes/pages/RRHH";
import Contacto from "./componentes/pages/Contacto";
import Inicial from "./componentes/pages/Inicial";
import Primario from "./componentes/pages/Primario";
import Secundario from "./componentes/pages/Secundario";
import Nocturno from "./componentes/pages/Nocturno";
import Footer from "./componentes/Footer";
// import paginaerror from "./componentes/paginaerror"
import { AnimatePresence} from "framer-motion";
import Login from "./componentes/Login";

function App() {
  return (
    <AnimatePresence>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/home" exact component={Inicio} />
          <Route path="/institucion" component={Institucion} />
          <Route path="/niveles" component={Niveles} />
          <Route path="/RRHH" component={RRHH} />
          <Route path="/contacto" component={Contacto} />
          <Route path="/inicial" component={Inicial} />
          <Route path="/primario" component={Primario} />
          <Route path="/secundario" component={Secundario} />
          <Route path="/nocturno" component={Nocturno} />
          <Route path="/login" component={Login} />
          <Redirect to="/home" />
          <Route path='*' component={Inicio} />
        </Switch>
        <Footer />
      </Router>
    </AnimatePresence>
  );
}

export default App;
