import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { verRequisitos, volverBTN } from "./ModalInscripciones.js";
import "../css/Niveles.css";
import "../css/Botones.css";
import Lottie from 'react-lottie';
import book from '../animations/book.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  renderSettings: {
    preserveAspectRadio: 'xMidYMid slice'
  }
}

export const PopoverSoyNuevo = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">Alumnos de otro colegio</Tooltip>
    }
  >
    <spam variant="success" className="popover-soy-nuevo pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);
export const PopoverSoyInst = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={
      <Tooltip id="button-tooltip-2">
        Alumnos de la institución (Inscripción para otro año)
      </Tooltip>
    }
  >
    <spam variant="success" className="popover-soy-inst pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);
export const PopoverPrimer = (
  <OverlayTrigger
    trigger="hover"
    placement="right"
    overlay={<Tooltip id="button-tooltip-2">Alumnos de 6to grado para inscribirse a primer año</Tooltip>}
  >
    <spam variant="success" className="popover-primero pointer-cursor">
      ?
    </spam>
  </OverlayTrigger>
);

const primeroMat = (
  <ul>
    <li>Lengua y Literatura</li>
    <li>Matematica</li>
    <li>Cs Nat - Biologia</li>
    <li>Cs Nat - Fisica</li>
    <li>Cs Soc - Geografia</li>
    <li>Ingles</li>
    <li>Musica</li>
    <li>Ed Tecnologica</li>
    <li>Ciudadania y Participacion</li>
    <li>Ed Fisica</li>
    <li>Informatica</li>
    <li>Orientacion Espiritual</li>
  </ul>
);
export const primero = {
  titulo: "1° Año",
  materias: primeroMat,
};

const segundoMat = (
  <ul>
    <li>Lengua y Literatura</li>
    <li>Matematica</li>
    <li>Cs Nat - Biologia</li>
    <li>Cs Nat - Quimica</li>
    <li>Cs Soc - Historia</li>
    <li>Ingles</li>
    <li>Plastica</li>
    <li>Ed Tecnologica</li>
    <li>Ciudadania y Participacion</li>
    <li>Ed Fisica</li>
    <li>Informatica</li>
    <li>Orientacion Espiritual</li>
  </ul>
);
export const segundo = {
  titulo: "2° Año",
  materias: segundoMat,
};
const terceroMat = (
  <ul>
    <li>Lengua y Literatura</li>
    <li>Matematica</li>
    <li>Cs Nat - Fisica</li>
    <li>Cs Nat - Quimica</li>
    <li>Cs Soc - Geografia</li>
    <li>Cs Soc - Historia</li>
    <li>Ingles</li>
    <li>Plastica / Musica</li>
    <li>Ed Tecnologica</li>
    <li>Formacion para la vida y trabajo</li>
    <li>Ed Fisica</li>
    <li>Orientacion Espiritual</li>
  </ul>
);
export const tercero = {
  titulo: "3° Año",
  materias: terceroMat,
};
const cuartoMat = (
  <ul>
    <li>Lengua y Literatura</li>
    <li>Matematica</li>
    <li>Cs Nat - Biologia</li>
    <li>Cs Soc - Geografia</li>
    <li>Cs Soc - Historia</li>
    <li>Ingles</li>
    <li>Plastica</li>
    <li>Ed Fisica</li>
    <li>Formacion para la vida y trabajo</li>
    <li>Antropologia Social y Cultural</li>
    <li>Metodologia de Inv en Cs Sociales</li>
    <li>Etica Cristiana</li>
  </ul>
);
export const cuarto = {
  titulo: "4° Año",
  materias: cuartoMat,
};
const quintoMat = (
  <ul>
    <li>Lengua y Literatura</li>
    <li>Matematica</li>
    <li>Cs Nat - Fisica</li>
    <li>Cs Soc - Geografia</li>
    <li>Cs Soc - Historia</li>
    <li>Ingles</li>
    <li>Musica</li>
    <li>Psicologia</li>
    <li>Ed Fisica</li>
    <li>Formacion para la vida y trabajo</li>
    <li>Sociologia</li>
    <li>Problematicas Eticas y Politicas</li>
    <li>Etica Cristiana</li>
  </ul>
);
export const quinto = {
  titulo: "5° Año",
  materias: quintoMat,
};
const sextoMat = (
  <ul>
    <li>Lengua y Literatura</li>
    <li>Matematica</li>
    <li>Cs Nat - Quimica</li>
    <li>Cs Soc - Geografia</li>
    <li>Cs Soc - Historia</li>
    <li>Ingles</li>
    <li>Teatro</li>
    <li>Ciudadania y Politica</li>
    <li>Filosofia</li>
    <li>Ed Fisica</li>
    <li>Formacion para la vida y trabajo</li>
    <li>Economia Politica</li>
    <li>Sociedad, Cultura y Comunicacion</li>
    <li>Etica Cristiana</li>
  </ul>
);
export const sexto = {
  titulo: "6° Año",
  materias: sextoMat,
};
export const primeroReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    <h2 style={{ fontWeight: 900 }}>1ER AÑO</h2>
    <br></br>
    {/* <div className="postalPublicitario"></div> */}
    <h4>
      <b>OFERTA DEL CENTRO EDUCATIVO</b>
    </h4>
    Este Centro de enseñanza realiza una oferta educativa Cristiana Evangélica
    en conformidad con los principios, objetivos y proyectos señalados en su
    Ideario Institucional.
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1aAzJbkRXSzod4KoyX0RkK6VI7EdaTFne/view"
        className="modal-button btn"
      >
        Ideario Institucional
      </a>
    </div>
    <p style={{ marginBottom: 0 }}>
      Sólo se puede presentar la solicitud de banco completando el formulario
      Online. El hecho de completar este formulario no significa la
      matriculación definitiva del niño/a.
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/aRuUx4H978DLuP5WA"
        className="modal-button btn"
      >
        Solicitud de banco
      </a>
    </div>
    <p>
      <b>
        Una vez completada la solicitud de pre-inscripción le llegara un correo
        electrónico indicando las formas de pago para afrontar el costo de la
        matrícula.
      </b>
    </p>
    {/* <h4>
      <b>PLAZO DE ABONO DE MATRICULA 2023</b>
    </h4>
    <p>
      Se puede realizar en un solo pago de $15.000, con una bonificación del 50%
      hasta el 15-12-2022.
      <br></br>De lo contrario a partir del 21 de Febrero 2023 deberá abonar el
      100% de su valor.
    </p>
    <h4>
      <b>MATRÍCULA DEFINITIVA DE 1° AÑO 2023:</b>
    </h4>
    <p style={{ marginTop: 20 }}>
      <b>
        *Si su hijo/a es alumno integrado, debe presentar primeramente en
        recepcion del colegio de 14hs a 18hs el informe de su psicopedagoga y
        maestra integradora, para ser analizado por direccion juntamente con el
        gabinete psicopedagogico, y asi concretar una entrevista, luego de la
        cual se le confirmara la vacante.
      </b>
    </p> */}
    {/* <br />
    <p>
      Una vez que realizó el pago de la Matrícula debe presentar en Original y
      fotocopia (para dejar) la siguiente Documentación.
    </p> */}
    <h3>Documentación</h3>
    <ul>
      <li>DNI actualizado (Original y fotocopia de ambos lados)</li>
      <li>
        DNI actualizado (Original y fotocopia de ambos lados) del Padre o Tutor
      </li>
      <li>
        Partida de nacimiento original ó fotocopia de Libreta de Familia (hoja
        con datos de padres y estudiante).
      </li>
      <li>Certificado de alumno regular de 6to grado.</li>
      <li><strong>
        Contrato de Servicios Educativos, (Luego de leer el contrato, imprimir,
        completar en todos los espacios, FIRMAR y adjuntar LA ÚLTIMA PÁGINA.)</strong>
      </li>
      <div className="posicionamientoboton ">
      <a className="modal-button btn" href={require('../img/contratosecundario2024.pdf')} download="CONTRATO SECUNDARIO 2024">Descargar contrato</a>
      </div>
      {/* <br /> */}
      {/* <p style={{marginLeft: -14}}><b>*DEBE COMPLETAR EL RESTO DE LOS REQUISITOS A PARTIR DEL 22 DE FEBRERO HASTA 01  DE MARZO DE 2022*</b></p> */}

      <li>Fotocopia libreta de calificaciones de 6to grado.</li>
      {/* <li>Certificado buco-dental de ente público u odontólogo particular.</li> */}
      <li>
        Fotocopia Carnet de Vacunación ó Certificado médico que acredite
        vacunación reglamentaria.
      </li>
      <li>1 foto carnet actualizada.</li>
      <li>Certificado de Libre Deuda (si proviene de colegio privado).</li>
      <li>
        CUS (Certificado único de salud). Completar y presentar en Febrero -
        Marzo
      </li>
      <div className="posicionamientoboton">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
          className="modal-button btn"
        >
          Descargar CUS
        </a>
      </div>
    </ul>
    <p>
      <b>
        NOTA: LA MATRÍCULA SOLO LA PUEDEN EFECTUAR LOS PADRES/TUTORES -NO DEBE
        FALTAR NINGÚN REQUISITO, DE LO CONTRARIO NO PODRÁ REALIZAR LA
        INSCRIPCIÓN- SIN EXCEPCIÓN.
      </b>
    </p>
  </div>
);

export const institucionReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    {/* <img className="img-promo"/> */}
    <h2 style={{ fontWeight: 900 }}>PERTENECIENTE A LA INSTITUCION</h2>
    <br></br>
    <h4>
      <b>OFERTA DEL CENTRO EDUCATIVO</b>
    </h4>
    Este Centro de enseñanza realiza una oferta educativa Cristiana Evangélica
    en conformidad con los principios, objetivos y proyectos señalados en su
    Ideario Institucional.
    <br />
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1aAzJbkRXSzod4KoyX0RkK6VI7EdaTFne/view"
        className="modal-button btn"
      >
        Ideario Institucional
      </a>
    </div>
    <h4>
      <b>FORMALIZACIÓN DE LAS SOLICITUDES</b>
    </h4>
    Sólo se puede presentar la solicitud de pre-inscripción completando el
    formulario Online. El hecho de completar este formulario no significa la
    matriculación definitiva del niño/a.
    <br></br>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/cFCntHevZjPAw86G6"
        className="modal-button btn"
      >
        Solicitud de PreInscripcion
      </a>
    </div>
    {/* <p>
      A partir del 1° de Octubre se podrá comenzar a abonar en 3 cuotas
      consecutivas de $5000 la pre-matrícula 2023 (Octubre-Noviembre-Diciembre,
      fecha límite el 15-12-2022) o en un pago único de $12.000 (únicamente del
      01 al 15 de Diciembre 2022).<br></br>Para acceder a éstos beneficios (si
      Ud opta por ésta modalidad) deberá tener las cuotas al día y abonar en
      forma conjunta pagos parciales de pre-matrícula 2023 y cuota 2022
      correspondientes a cada mes.
    </p> */}
    <h4>
      <b>MATRÍCULA DEFINITIVA DE 2° A 6° 2025 PARA ALUMNOS DEL COLEGIO:</b>
    </h4>
    <p>
      Una vez Cancelado el Monto Total de la Matrícula, y finalizado el ciclo
      Lectivo, es decir, a partir del 16 de Diciembre, deberá completar la
      Matrícula Definitiva presentando la siguiente Documentación en Original y
      fotocopia (para dejar), de 14hs a 18hs, con el Preceptor a cargo.<br></br>
      Sin los siguientes requisitos NO SE REALIZARÁ LA INSCRIPCIÓN. SIN
      EXCEPCIÓNES.
    </p>
    <ul>
      <li>
        <p>Comprobante de pago Total de Matrícula.</p>
      </li>
      <li>
        <p>Fotocopia DNI (alumno/a y padres/Tutor).</p>
      </li>
      <li>
        <p>Informe Escolar 2024.</p>
      </li>
      <li>
        <p>
          Última página del Ideario y Contrato Escolar con sus firmas de
          aceptación  y Consentimiento.
        </p>
      </li>
      <li>
        <p>CUS: Debe presentarlo en Febrero-Marzo 2025.</p>
      </li>
    </ul>
    <h4>
      <b>Documentacion a presentar:</b>
    </h4>
    <p><strong>
      Firma de Conformidad y Compromiso respecto del Ideario Institucional y del
      Contrato Educativo (última página del PDF)</strong>
    </p>
    <div className="posicionamientoboton">
      <a className="modal-button btn" href={require('../img/contratosecundario2024.pdf')} download="CONTRATO SECUNDARIO 2024">Descargar contrato</a>
    </div>
    <p>
      CUS: deberá ser presentado en Febrero/Marzo 2025, sin el cual NO podrá
      cursar.
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
        className="modal-button btn"
      >
        Descargar CUS
      </a>
    </div>
    <p>
      <b>
        (Nota: Los alumnos que no formalicen la matrícula en los plazos
        establecidos perderán su plaza)
      </b>{" "}
    </p>
  </div>
  
);

export const nuevoReq = (
  <div className="card-modal m-3">
    <spam onClick={() => volverBTN(botonesElegir)}>
      <i class="fas fa-arrow-left"></i>
    </spam>
    <h2 style={{ fontWeight: 900 }}>SOY NUEVO</h2>
    <br></br>
    <p style={{ margin: 30 }}>
      Para interesados de otros Colegios en ingresar al Nivel Medio de nuestra
      institución,<b> completar el siguiente formulario:</b>
    </p>
    <div className="posicionamientoboton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/8q4R9mKKRWMceGP17"
        className="modal-button btn"
      >
        Solicitud requerida
      </a>
    </div>
    <br></br>
    <p style={{ marginLeft: 30 }}>
      <b>
        *El solo hecho de completar este formulario no significa la admisión del
        alumno/a.
      </b>
    </p>
    <ul>
      <li>
        <p>
          Una vez que Ud. complete la solicitud de vacante y adjunte los
          Informes Escolares solicitados, le enviaremos al mail informado la
          respuesta de vacante. En caso de confirmación, enviaremos la lista de
          Requisitos a presentar para realizar la Matrícula Definitiva. La
          atención será de Lunes a Viernes de 14 a 18hs en la Secretaría del
          Colegio. La misma debe ser realizada solo por Padres y/o Tutores, y NO
          puede faltar ningún requisito. SIN EXCEPCIÓN."
          <br></br>
          RECUERDE: Descargar el Ideario y CUS a continuacion.
        </p>
        <div className="posicionamientoboton">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1aAzJbkRXSzod4KoyX0RkK6VI7EdaTFne/view"
            className="modal-button btn"
          >
            Ideario Institucional
          </a>
        </div>
      </li>
      <li>
        <p>
          <strong>Contrato de Servicios Educativos, (Luego de leer el contrato,
          imprimir, completar en todos los espacios, FIRMAR y adjuntar LA ÚLTIMA
          PÁGINA.)</strong>
        </p>
        <div className="posicionamientoboton">
          <a className="modal-button btn" href={require('../img/contratosecundario2024.pdf')} download="CONTRATO SECUNDARIO 2024">Descargar contrato</a>
        </div>
      </li>  
      <li>
        <p>CUS (Certificado único de salud).</p>
      </li>
      <div className="posicionamientoboton">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://drive.google.com/file/d/1idrURzoR7YEAIjPxlP9NUr8Mn2V_Y0WY/view"
          className="modal-button btn"
        >
          Descargar CUS
        </a>
      </div>
    </ul>
  </div>
);

export const botonesElegir = (
  <div className="botones-secundario">
    <Lottie width='200px' options={{animationData: book, ...defaultOptions}} />
            <h3 className="modal-title text-center">Bienvenido</h3>
            <p className="modal-text text-center">Para la reserva de banco elija la <br/> opción y complete el formulario.</p>
    <div>
    <hr/>
      <spam
        className="modal-button btn"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(nuevoReq)}
      >
        <span>Nuevos para 2° a 6° año</span>
      </spam>{" "}
      {PopoverSoyNuevo}
    </div>
    <div>
      <spam
        className="modal-button btn"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(institucionReq)}
      >
        <span>Alumnos de nuestro colegio</span>
      </spam>{" "}
      {PopoverSoyInst}
    </div>
    <div>
      <spam
        className="modal-button btn"
        data-sm-link-text="Ver requisitos"
        onClick={() => verRequisitos(primeroReq)}
      >
        <span>Nuevos para 1° año</span>{" "}
      </spam>{" "}
      {PopoverPrimer}
    </div>
  </div>
);
