import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { primero, cuarto, botonesElegir } from "../ConstantesPrimario.js";
import TituloSubtitulo from "../TituloSubtitulo";
import ContactNiveles from "../ContactNiveles";
import Encabezado from "../Encabezado";
import { Parallax } from "react-parallax";
// import ModalInscripciones from "../ModalInscripciones.js";
// import imageFloat4 from "../../img/nene-4.png";
// import EncabezadoSimple from "../EncabezadoSimple";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../css/CuerpoSecundario.css";
import "../../css/Niveles.css";
import "../../css/Botones.css";
import "../../css/Primario.css";
import "../Button";
import DropdownList from "../DropdownList";
import EncabezadoSimple from "../EncabezadoSimple";
import ModalInscripciones from "../ModalInscripciones.js";

export default function Primario() {
  useEffect(() => {
    Aos.init({});
    Aos.refresh({});
  }, []);

  return (
    <>
      <EncabezadoSimple clases="gris" />
      <div
        data-aos="fade-down"
        data-aos-duration="1000"
        className="encabezadoPrimario mb-1"
      >
        <div data-aos="fade-up" data-aos-duration="2000" className="posBotNoc">
          <p className="bt-class positionModalPri  ">
            <ModalInscripciones
              classBtn={"bt-1-white"}
              titulo={"Inscripción Primario"}
              sub1={"Inscripción"}
              sub2={"Requisitos"}
              botones={botonesElegir}
            />
          </p>
        </div>
      </div>

      {/* Titulo, y dos subtitulos */}
      <TituloSubtitulo
        subtitulo="Nivel Primario"
        text="Nuestros/as estudiantes del Nivel Primario comienzan el camino de potenciar su desarrollo intelectual, creativo, la autoestima, la expresividad y las relaciones interpersonales a través de una enseñanza integral, artística y musical. Cada uno/a es reconocido en su individualidad y no como parte de una masa. Aportamos así una propuesta diferente entre colegios primarios privados en Córdoba."
      />
      {/* Separador con foto */}
      <div className="separador">
        <Parallax
          bgImage={require("../../img/jardinModif3.jpg")}
          className={"separador-image-2"}
        />
      </div>

      {/* ////////////////////////// */}
      <Container className=" salas-conocimiento">
        <Row>
          <Col md={6} className="salitas">
            <h3 className="salitas-subtitulo c1">Nivel Primario</h3>
            <h1 className="salitas-titulo">Materias por grados en:</h1>
            <DropdownList lista={[primero, cuarto]} />
            <h1>Turno: Mañana</h1>
          </Col>
          <Col md={6}>
            <div className="cuadrito">
              <h2>Campos de Conocimiento:</h2>
            </div>
            <div className="cuadrito-text">
              <p>
                Durante los seis años de estudio, los estudiantes desarrollan la
                capacidad de percepción, análisis y observación para llegar a la
                construcción y generación de pensamientos, conocimientos y
                destrezas.
                <br />
                <br />
                Contarán con contenidos y actividades para ampliar horizontes de
                pensamiento y emociones, y expandirán la manera de mirar,
                pensar, sentir, crear y comunicar.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <ContactNiveles
        color="c2"
        nivel="primario"
        emailSend="primariomorris@gmail.com"
      />
    </>
  );
}
