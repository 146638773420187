import React, {useEffect} from "react";
import { Parallax } from "react-parallax";
import "../css/Encabezados.css";
import Aos from 'aos';
import "aos/dist/aos.css";

export default function Encabezado(props) {

  useEffect(() => {
    Aos.init({});
  }, [])
  return (
    <>
      <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000">
        <Parallax
          strength={props.strength}
          bgImage={props.fondo}
          className={"niveles-encabezado"}
          >
          <div className="cabeza">
            <div className="capa-gris"></div>
            <div 
            className="niveles-encabezado-titulo">
                <h4 data-aos="fade-up"
                  data-aos-duration="1000">{props.nivel.toUpperCase()}</h4>
                <h1 data-aos="fade-up"
                  data-aos-duration="3000">{props.frase}</h1>
                {props.condicional ? <h2 data-aos="fade-up"
                  data-aos-duration="3000">-William c. Morris</h2> : " "}
            </div>
            <div className="onda">
              <svg
                viewBox="0 0 500 150"
                preserveAspectRatio="none"
                style={{ height: "100%", width: "100%" }}
                >
                <path
                  d="M0.00,49.98 C131.77,153.45 378.38,149.50 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                  style={{ stroke: "none", fill: "white" }}
                  />
              </svg>
            </div>
          </div>
        </Parallax>
      </div>
    </>
  );
}
