import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Encabezado from "../Encabezado";
import TituloSubtitulo from "../TituloSubtitulo";

export default function Niveles() {
  return (
    <>
      <Encabezado
        fondo={require("../../img/aula.jpg")}
        nivel={"Niveles de cursado"}
        frase={"Mas de 60 años educando en valores cristianos"}
        strength={300}
      />
      <TituloSubtitulo
        subtitulo="Nuestros Niveles Educativos"
        text=""
      />
      <Cards />
    </>
  );
}
