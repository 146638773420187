import React, {useEffect} from "react";
import { Container} from "react-bootstrap";
//import "../css/Niveles.css";
import "../css/Separador.css";
import "../css/TituloSubtitulo.css"
import Aos from 'aos';
import "aos/dist/aos.css";

export default function TituloSubtitulo(props) {

  useEffect(() => {
    Aos.init({});
  }, [])

  return (
    <>
      <Container className="container" data-aos="fade-up">
        <section className="section-titulo-nivel">
          <div className="titulo-nivel">
            <h2>{props.subtitulo}</h2>
            {!props.condicional ? 
            <>
            <h3>
              <b>William C. Morris</b>
            </h3>
            <h1>Colegio Cristiano Evangelico</h1>
            </>
            : " " }
            <hr></hr> 
          </div>
          <p className="text-parrafo">{props.text}</p>
        </section>
      </Container>
    </>
  );
}
