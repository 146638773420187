import React from "react";
import { Row, Col } from "react-bootstrap";
import "../css/Footer.css";
import LOGO from "../img/LOGO.png";

export default function () {

  let thisYear = new Date().getFullYear()

  return (
    <>
      <div className="middle ">
        <Row className="mt-0 mb-0 pt-0 pb-0">
          <Col sm={6} md={6}>
            <p className="contacto">
              Email: info.williamcmoris@gmail.com <br />
              Direccion: José de Calasanz 144<br/>(Córdoba, Argentina) <br />
              Tel: 0351-4232972 / 0351-4239991
            </p>
          </Col>
          <Col sm={6} md={6} className="icons">
            <a
              href="https://www.facebook.com/colegiowilliamcmorris/"
              class="boton"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/colegiowilliamcmorris/"
              class="boton"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UC1CrFtJJg9E4j9j2qcncI3Q"
              class="boton"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-youtube"></i>
            </a>
            <a
              href="https://www.google.com/maps/place/Colegio+Cristiano+Evang%C3%A9lico+William+C.+Morris/@-31.414021,-64.196764,16z/data=!4m5!3m4!1s0x0:0xa313d7712939b91e!8m2!3d-31.4140206!4d-64.1967636?hl=es-419"
              target="_blank"
              rel="noopener noreferrer"
              class="boton"
            >
              <i class="fas fa-map-marker-alt"></i>
            </a>
          </Col>
        </Row>
        <a href="/">
          <img
              src={LOGO}
              className="logo-william-footer"
              alt="Logo william c morris"
            />
        </a>
        <p className="bt-personal">
					<a href="/personal" className="bt-3">Personal</a>
				</p>
        <p className="derecho">© {thisYear} William C. Morris, Inc. · Privacy · Terms</p>
      </div>
    </>
  );
}
